import React from "react"
import Layout from "../components/layout"
import aboumainsectionimg from "../images/about-main-sectionimg.svg"
import teamsectionimgone from "../images/team-section-imgone.png"
import teamsectionimgtwo from "../images/team-section-imgtwo.png"
import Finalctablock from "../components/final-cta-block"
import { Helmet } from "react-helmet"

const about = () => {
  return (
    <Layout>
      <Helmet>
        <title>About us | InHero</title>
        <meta
          name="description"
          content="Learn more about InHero, our story and team."
        />
      </Helmet>

      <div className="container stripeanchor">
        <div className="about-stripe-left"></div>
        <section class="about-hero-section">
          <h1>Turning local businesses into digital businesses</h1>
          <p>
            InHero is building tools that make marketing, operations, and
            communication convenient for local businesses. We want local
            businesses to be easy to run, grow, and buy from.
          </p>
        </section>
        <div className="about-stripe-right"></div>
      </div>

      <div className="about-grad">
        <div className="container">
          <section class="about-main-section">
            <div className="about-main-section-txt">
              <h2>It all started with a tile shop.</h2>

              <p>
                A while ago we got a call from a title shop owner who was
                struggling to compete online. Even though he had tons of happy
                customers, mostly angry customers were leaving reviews. His
                reviews didn’t reflect his great service and his years of happy
                customers.
              </p>

              <p>
                Not only this, people would have to call his shop to get a quote
                and do business in general which was inconvenient compared to
                his online competition. So we’re setting out to provide
                experiences that are convenient for local businesses and their
                customers, starting by making reviews easy.
              </p>
            </div>

            <div className="about-main-section-img">
              <img src={aboumainsectionimg} alt="" />
            </div>
          </section>
        </div>
      </div>

      <Finalctablock />
    </Layout>
  )
}

export default about
